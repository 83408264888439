import { useTheme } from '@emotion/react'
import { transparentize } from 'polished'

import { rotate360 } from '@/constants/animations'

import { ColorVariant, StyleVariant } from '.'

type SpinnerColors = {
  empty: string
  full: string
}

export const Loading: React.FC<{ colorVariant: ColorVariant; styleVariant: StyleVariant }> = ({
  colorVariant,
  styleVariant,
}) => {
  const { colors } = useTheme()

  const colorMap: Record<ColorVariant, Record<StyleVariant, SpinnerColors>> = {
    dark: {
      primary: {
        empty: transparentize(0.5, colors.greyscale[0]!),
        full: colors.greyscale[0]!,
      },
      secondary: {
        empty: colors.greyscale[400],
        full: colors.greyscale[600],
      },
      tertiary: {
        empty: colors.greyscale[400],
        full: colors.greyscale[600],
      },
    },
    light: {
      primary: {
        empty: colors.greyscale[400],
        full: colors.greyscale[600],
      },
      secondary: {
        empty: transparentize(0.5, colors.greyscale[0]!),
        full: colors.greyscale[0]!,
      },
      tertiary: {
        empty: transparentize(0.5, colors.greyscale[0]!),
        full: colors.greyscale[0]!,
      },
    },
  }

  const { empty, full } = colorMap[colorVariant][styleVariant]

  return (
    <span
      css={{
        animation: `${rotate360} 0.6s ease-out infinite`,
        background: 'transparent',
        borderBottom: `1px solid ${empty}`,
        borderLeft: `1px solid ${empty}`,
        borderRadius: '100%',
        borderRight: `1px solid ${empty}`,
        borderTop: `2px solid ${full}`,
        boxSizing: 'border-box',
        display: 'block',
        height: '28px',
        left: 'calc(50% - 17px)',
        lineHeight: '46px',
        width: '28px',
      }}
      data-testid="loading-spinner"
    ></span>
  )
}
