import { NavigationLink } from '@/types/global'

/* Links */

const trasparenza: NavigationLink = {
  label: 'Trasparenza',
  url: '/trasparenza',
}

const privacy: NavigationLink = {
  label: 'Privacy Cliente',
  url: '/privacy',
}

const privacyWeb: NavigationLink = {
  label: 'Privacy Web',
  url: '/privacy-web',
}

const terminiECondizioni: NavigationLink = {
  label: 'Termini e condizioni',
  url: '/termini-e-condizioni',
}

const preventivoMutuo: NavigationLink = {
  label: 'Preventivo Mutuo',
  url: '/come-possiamo-aiutarti',
}

const casavoMutuiPerche: NavigationLink = {
  label: 'Casavo Mutui Perché',
  url: '/casavo-mutui-perche',
}

const banchePartner: NavigationLink = {
  label: 'Banche Partner',
  url: '/banche-partner',
}

const contattiSupporto: NavigationLink = {
  label: 'Contatti e supporto',
  url: '#footer-contacts',
}

const rendicontoReclami: NavigationLink = {
  external: true,
  label: 'Rendiconto reclami',
  url: '/rendiconto-reclami',
}

/* Link collections */

export const subNavLinks: NavigationLink[] = [
  trasparenza,
  privacy,
  privacyWeb,
  terminiECondizioni,
  banchePartner,
  /* { ...banchePartner, external: true }, */
]

export const mainMenuLinks: NavigationLink[] = [preventivoMutuo, casavoMutuiPerche, contattiSupporto]

export const footerLegalLinks: NavigationLink[] = [trasparenza, privacy, terminiECondizioni, rendicontoReclami]

export const footerMortgageLinks: NavigationLink[] = [preventivoMutuo]

export const footerCompanyLinks: NavigationLink[] = [casavoMutuiPerche, banchePartner]

export const mobileMenuLinks: Record<string, NavigationLink[]> = {
  company: [casavoMutuiPerche, banchePartner],
  legal: [trasparenza, privacy, terminiECondizioni],
  mortgage: [preventivoMutuo],
}
