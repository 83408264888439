/**
 * from https://www.iubenda.com/en/help/27137-google-consent-mode
 * see also https://developers.google.com/tag-platform/devguides/consent
 *
 * plus custom consents not managed by google
 *   - experience_enhancement_storage
 *   - basic_interactions_storage
 *   - strictly_necessary_storage
 *
 * url_passthrough -> Improve ad click measurement quality (optional)
 * ads_data_redaction -> Further redact your ads data (optional)
 */

export const getInitialConsent = () => {
  return `
  window.dataLayer = window.dataLayer || []
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("consent", "default", {
    "ad_storage": "denied",
    "analytics_storage": "denied",
    "experience_enhancement_storage": "denied",
    "basic_interactions_storage": "denied",
    "strictly_necessary_storage": "granted",
    "wait_for_update": 2000
  });
  gtag("set", "url_passthrough", true);
  gtag("set", "ads_data_redaction", true);
  `
}
