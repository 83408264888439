import React, { useEffect, useState } from 'react'

import { Grid } from '@/components/baseUI/Grid'
import { useDevice } from '@/context/device-context'
import { mq } from '@/style-theme/media-queries'

import { HeaderContent } from './HeaderContent'

export const Header: React.FC = () => {
  const device = useDevice()

  const [isCompact, setIsCompact] = useState<boolean>(false)

  useEffect(() => {
    const scrollTreshold: number = device === 'desktop' ? 380 : 130
    const toggleHeaderVersion = () => {
      if (window.scrollY > scrollTreshold) setIsCompact(true)
      else setIsCompact(false)
    }

    document.addEventListener('scroll', toggleHeaderVersion)
    return () => document.removeEventListener('scroll', toggleHeaderVersion)
  }, [device])

  return (
    <header
      css={(theme) => ({
        alignItems: 'center',
        background: isCompact ? theme.colors.greyscale[0] : theme.colors.lightBlue,
        borderBottom: isCompact ? `1px solid ${theme.colors.greyscale[300]}` : 'none',
        display: 'flex',
        height: isCompact ? 64 : 80,
        justifyContent: 'space-between',
        marginBottom: isCompact ? 16 : 0, // This is used to avoid absolute elements in page to shift when menu height changes
        position: 'sticky',
        top: 0,
        transition: 'all 0.3s ease',
        width: '100%',
        [mq.large]: {
          height: isCompact ? 80 : 112,
          marginBottom: isCompact ? 32 : 0, // This is used to avoid absolute elements in page to shift when menu height changes
        },
        zIndex: 10,
      })}
      data-testid="app-header"
    >
      {device === 'mobile' ? (
        <HeaderContent />
      ) : (
        <Grid>
          <HeaderContent />
        </Grid>
      )}
    </header>
  )
}
