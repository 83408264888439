import { IconProps } from './types'

export const SymbolLogo: React.FC<IconProps> = ({
  dataTestId,
  fillColor,
  height = 33,
  viewBox = '0 0 29 33',
  width = 29,
}) => {
  return (
    <svg
      data-testid={dataTestId}
      fill={fillColor}
      height={height}
      viewBox={viewBox}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.740387 21.1825C2.69319 27.9068 9.04043 32.8431 15.9424 32.9903C18.0147 33.0614 20.2148 32.732 22.2696 31.9778C22.4847 31.899 22.4621 31.5851 22.2386 31.5361C16.4493 30.2729 11.473 25.936 10.2299 20.0162C9.08757 14.5756 10.7369 9.01168 13.6105 4.37249C14.0819 3.61178 14.5624 2.87625 15.1119 2.15557C15.3179 1.88564 15.4574 1.63767 15.8229 1.71968C16.1665 1.79652 16.2013 2.10649 16.3144 2.60115C17.4709 7.65622 22.3342 11.454 27.7199 11.6051C27.9033 11.6103 28.0538 11.4598 28.0538 11.2757V0.406188C28.0538 0.182106 27.8723 0 27.6476 0H16.4435V0.0019373C9.68426 0.0684513 3.89625 4.18845 1.39197 10.0475C1.39197 10.0475 -1.24535 15.0406 0.740387 21.1825Z"
        fill="#1D1D1B"
      />
    </svg>
  )
}
