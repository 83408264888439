import { useEffect } from 'react'

import { dataLayerPush } from '../utils/tracking/trackingUtils'

export const useTagManager = (props?: object): void => {
  useEffect(() => {
    dataLayerPush({
      event: 'casavomutui.pageview',
      ...props,
    })
  })
}
