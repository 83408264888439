import { IconProps } from './types'

export const Phone: React.FC<IconProps> = ({
  dataTestId,
  fillColor,
  height = 16,
  viewBox = '0 0 16 16',
  width = 16,
}) => {
  return (
    <svg
      data-testid={dataTestId}
      fill="none"
      height={height}
      viewBox={viewBox}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M7.10371 3.48751C7.10371 0.88992 5.51349 0 3.55186 0C1.59022 0 0 1.56141 0 3.48751C0.0166467 11.5703 6.17254 16 12.4093 16C15.1424 16 16 14.1974 16 12.4422C16 10.687 15.2008 8.88252 12.4093 8.88252C11.6395 8.88252 11.0021 9.23032 10.5372 9.73812C9.06203 11.1392 4.8045 6.6744 6.17254 5.30638C6.71075 4.82958 7.10371 4.24448 7.10371 3.48751ZM2 3.64368C2 2.74545 2.75873 2 3.712 2C4.97643 2 5.42399 2.38125 5.42399 3.64723C5.42399 3.76499 5.35177 3.89618 5.10449 4.11539L5.02911 4.18639L4.9349 4.2855C2.24244 7.2661 8.6163 13.7806 11.6235 10.9228L11.7926 10.7531C11.9358 10.6264 12.0849 10.5681 12.2506 10.5681C13.5863 10.5681 14 11.074 14 12.285C14 13.4636 13.5551 14 12.2506 14C6.52176 14 2.01284 9.88268 2 3.64368Z"
        fill={fillColor ? fillColor : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}
