import Link from 'next/link'

import { NavigationLink } from '@/types/global'

export const FooterLink: React.FC<NavigationLink> = ({ external, label, url }) => {
  return (
    <li>
      {external ? (
        <a href={url} rel="noopener noreferrer" target="_blank">
          {label}
        </a>
      ) : (
        <Link passHref href={url}>
          <a>{label}</a>
        </Link>
      )}
    </li>
  )
}
