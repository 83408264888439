import React from 'react'

import { getInitialConsent } from './consentConfig'

const ConsentSnippet: React.FC = () => {
  return (
    <>
      <script dangerouslySetInnerHTML={{ __html: getInitialConsent() }} />
    </>
  )
}

export { ConsentSnippet }
