import styled from '@emotion/styled'
import { ElementType } from 'react'

import { mq } from '@/style-theme/media-queries'

type Props = {
  as?: ElementType<any> | undefined
  className?: string
  dataTestId?: string
  level: 1 | 2 | 3 | 4 | 'display'
}

export const Heading: React.FC<Props> = ({ as, children, className, dataTestId, level }) => {
  switch (level) {
    case 'display':
      return (
        <BaseUIDisplay as={as} className={className} data-testid={dataTestId}>
          {children}
        </BaseUIDisplay>
      )
    case 1:
    default:
      return (
        <BaseUIH1 as={as} className={className} data-testid={dataTestId}>
          {children}
        </BaseUIH1>
      )
    case 2:
      return (
        <BaseUIH2 as={as} className={className} data-testid={dataTestId}>
          {children}
        </BaseUIH2>
      )
    case 3:
      return (
        <BaseUIH3 as={as} className={className} data-testid={dataTestId}>
          {children}
        </BaseUIH3>
      )
    case 4:
      return (
        <BaseUIH4 as={as} className={className} data-testid={dataTestId}>
          {children}
        </BaseUIH4>
      )
  }
}

const BaseUIDisplay = styled.h1({
  fontFamily: 'Bagoss Condensed',
  fontSize: 40,
  fontWeight: 300,
  lineHeight: '48px',
  [mq.large]: {
    fontSize: 52,
    fontWeight: 300,
    lineHeight: '62px',
  },
})

const BaseUIH1 = styled.h1({
  fontFamily: 'Bagoss Condensed',
  fontSize: 32,
  fontWeight: 300,
  lineHeight: '38px',
  [mq.large]: {
    fontSize: 52,
    lineHeight: '62px',
  },
})

const BaseUIH2 = styled.h2({
  fontFamily: 'Bagoss Condensed',
  fontSize: 28,
  fontWeight: 300,
  lineHeight: '34px',
  [mq.large]: {
    fontSize: 32,
    lineHeight: '38px',
  },
})

const BaseUIH3 = styled.h3({
  fontFamily: 'Inter',
  fontSize: 24,
  fontWeight: 600,
  lineHeight: '29px',
  [mq.large]: {
    fontSize: 28,
    lineHeight: '34px',
  },
})

const BaseUIH4 = styled.h4({
  fontFamily: 'Inter',
  fontSize: 20,
  fontWeight: 600,
  lineHeight: '24px',
  [mq.large]: {
    fontSize: 24,
    lineHeight: '29px',
  },
})
