import styled from '@emotion/styled'

import { mq } from '@/style-theme/media-queries'

export const BodyLarge = styled.p({
  fontFamily: 'Inter',
  fontSize: 18,
  lineHeight: '27px',
  [mq.large]: {
    fontSize: 20,
    lineHeight: '30px',
  },
})

export const BodyMedium = styled.p({
  fontSize: 16,
  lineHeight: '24px',
})
