import Link from 'next/link'
import React from 'react'

import { BodyMedium } from '@/components/baseUI/Typography/BodyText'
import { Description } from '@/components/baseUI/Typography/Description'
import { SymbolLogo } from '@/components/icon/SymbolLogo'
import { footerCompanyLinks, footerLegalLinks, footerMortgageLinks } from '@/constants/menus'
import { OAMcertificationUrl } from '@/constants/OAMcertificationUrl'
import { useDevice } from '@/context/device-context'
import { sendInteractionEvent } from '@/utils/tracking/eventHandlers'

import { FooterLink } from './FooterLink'
import { Column, FloatingLogo, Links, Wrapper } from './styles'

type Props = {
  className?: string
}

export const Footer: React.FC<Props> = ({ className }) => {
  const device = useDevice()

  return (
    <div
      css={(theme) => ({
        backgroundColor: theme.colors.greyscale[600],
      })}
      id="site-footer"
    >
      <footer className={className}>
        <div
          css={(theme) => ({
            backgroundColor: theme.colors.greyscale[600],
            color: theme.colors.greyscale[0],
            padding: '60px 16px',
            position: 'relative',
          })}
        >
          <FloatingLogo>
            <SymbolLogo />
          </FloatingLogo>
          <Wrapper>
            <Column aria-label="link legal">
              <BodyMedium as={'h3'} css={{ fontWeight: 600 }}>
                Legal
              </BodyMedium>
              <Links>
                {footerLegalLinks.map((link) => (
                  <FooterLink key={link.url} external={link.external} label={link.label} url={link.url} />
                ))}
              </Links>
            </Column>
            <Column aria-label="link mutuo">
              <BodyMedium as={'h3'} css={{ fontWeight: 600 }}>
                Il tuo mutuo
              </BodyMedium>
              <Links>
                {footerMortgageLinks.map((link) => (
                  <FooterLink key={link.url} external={link.external} label={link.label} url={link.url} />
                ))}
              </Links>
            </Column>
            <Column aria-label="link casavomutui">
              <BodyMedium as={'h3'} css={{ fontWeight: 600 }}>
                CasavoMutui
              </BodyMedium>
              <Links>
                {footerCompanyLinks.map((link) => (
                  <FooterLink key={link.url} external={link.external} label={link.label} url={link.url} />
                ))}
              </Links>
            </Column>
            <Column aria-label="link di contatto" data-testid="footer-contacts" id="footer-contacts">
              <BodyMedium as={'h3'} css={{ fontWeight: 600 }}>
                Contatto e supporto
              </BodyMedium>
              <Links>
                <li>
                  <a
                    href={'mailto:info@casavomutui.com'}
                    onClick={() => {
                      sendInteractionEvent({
                        device: device,
                        event: 'ClickFooterEmailLink',
                        interaction_target: 'FooterEmailLink',
                        interaction_type: 'Click',
                      })
                    }}
                  >
                    info@casavomutui.com
                  </a>
                </li>
                <li>
                  Per reclami: <a href="mailto:casavomutui@pec.it">casavomutui@pec.it</a>
                </li>
              </Links>
            </Column>
          </Wrapper>
        </div>
        <div
          css={(theme) => ({
            backgroundColor: theme.colors.greyscale[600],
            color: theme.colors.greyscale[0],
            padding: '12px 16px',
            paddingBottom: theme.spacings['3xl'],
            textAlign: 'center',
          })}
        >
          <div css={(theme) => ({ marginBottom: theme.spacings['xs'] })}>
            <small>
              <a
                css={(theme) => ({ ':hover': { textDecoration: 'underline' }, marginRight: theme.spacings.s })}
                href="https://www.iubenda.com/privacy-policy/24801522/cookie-policy"
                rel="noopener noreferrer"
                target="_blank"
              >
                Cookie Policy
              </a>
              <span>-</span>
              <Link passHref href="/privacy-web">
                <a
                  css={(theme) => ({
                    ':hover': { textDecoration: 'underline' },
                    marginLeft: theme.spacings.s,
                  })}
                >
                  Privacy Web
                </a>
              </Link>
            </small>
          </div>
          <Description as={'small'}>
            © Casavo Mutui Srl |{' '}
            <a
              css={{ '&:hover': { textDecoration: 'underline' } }}
              href={OAMcertificationUrl}
              rel="noreferrer"
              target="_blank"
            >
              Iscrizione Elenco Mediatori Creditizi presso OAM n. M536
            </a>{' '}
            | Iscrizione R.E.A. n. MI - 2627745 | Iscrizione IVASS RUI sezione “E” al n. E000705870 | P.IVA: IT
            11849430969
          </Description>
        </div>
      </footer>
    </div>
  )
}
