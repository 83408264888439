import React from 'react'

import { Burger } from '@/components/icon/Burger'
import { Close } from '@/components/icon/Close'

type Props = {
  handleClick: VoidFunction
  type: 'open' | 'close'
}

export const MobileMenuButton: React.FC<Props> = ({ handleClick, type }) => {
  return (
    <div
      css={(theme) => ({
        alignItems: 'center',
        color: theme.colors.greyscale[600],
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
      })}
      data-testid="mobile-menu-button"
      role={'button'}
      tabIndex={0}
      onClick={handleClick}
      onKeyPress={(e) => {
        if (e.key === 'Enter') handleClick()
      }}
    >
      {type === 'open' ? (
        <React.Fragment>
          <Burger />
          <span css={{ fontSize: 11, fontWeight: 700 }}>Menu</span>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Close />
          <span css={{ fontSize: 11, fontWeight: 700 }}>Chiudi</span>
        </React.Fragment>
      )}
    </div>
  )
}
