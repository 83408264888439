import styled from '@emotion/styled'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'

import { Heading } from '@/components/baseUI/Typography/Heading'
import { Envelope } from '@/components/icon/Envelope'
import { Phone } from '@/components/icon/Phone'
import { LinkButton } from '@/components/LinkButton'
import { contactInfo } from '@/constants/contactInfo'
import { mobileMenuLinks } from '@/constants/menus'

import { HeaderLink } from './HeaderLink'
import { MobileMenuButton } from './MobileMenuButton'

type Props = {
  closeMenu: VoidFunction
}

export const MobileMenu: React.FC<Props> = ({ closeMenu }) => {
  /**
   * HACK
   * WHAT: Check if the menu modal is higher than the viewport. If so, add extra padding bottom to allow Y axis scroll.
   *       The amount of extra padding is the difference between the height of the menu and the height of the viewport
   *       plus 24px.
   * WHY: in order to block body scroll when the modal is open we have to set the body position to 'fixed' (thank you iOs Safari -,-")
   *      as such we need a hacky way to allow vertical scrolling when the menu is higher than the viewport.
   * CONSEQUENCES: None, but the implementation is pretty whacky. If you find a better solution you're welcome to implement it.
   */
  const [additionalPadding, setAdditionalPadding] = useState<number>(0)

  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (modalRef.current) {
      const extraPadding =
        modalRef.current.clientHeight >= window.innerHeight
          ? modalRef.current.clientHeight - window.innerHeight + 24
          : 0
      setAdditionalPadding(extraPadding)
    }
  }, [])

  /** Block page scroll when menu is open */
  useEffect(() => {
    document.body.classList.add('overlay')
    return () => document.body.classList.remove('overlay')
  }, [])

  return (
    <MenuModal key="menu" ref={modalRef} data-testid="menu-modal" paddingBottom={additionalPadding}>
      <MenuWrapper>
        <div
          css={(theme) => ({
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0 auto',
            marginTop: 14,
            paddingLeft: theme.spacings['m'],
            paddingRight: theme.spacings['m'],
          })}
        >
          <Link passHref href={'/'}>
            <a
              aria-label="Homepage"
              onClick={() => {
                closeMenu()
              }}
            >
              <Image alt="" height={46} src="/logo-mobile.svg" width={110} />
            </a>
          </Link>
          <MobileMenuButton handleClick={closeMenu} type="close" />
        </div>
        <MobileNavigation>
          <Heading level={1}>Il tuo mutuo</Heading>
          <LinkBlock>
            {mobileMenuLinks.mortgage.map((link) => (
              <HeaderLink key={link.url} closeMenu={closeMenu} label={link.label} url={link.url} />
            ))}
          </LinkBlock>
          <Divider />
          <Heading level={1}>Casavo Mutui</Heading>
          <LinkBlock>
            {mobileMenuLinks.company.map((link) => (
              <HeaderLink key={link.url} closeMenu={closeMenu} label={link.label} url={link.url} />
            ))}
          </LinkBlock>
          <Divider />
          <Heading as={'h2'} level={1}>
            Legal
          </Heading>
          <LinkBlock>
            {mobileMenuLinks.legal.map((link) => (
              <HeaderLink key={link.url} closeMenu={closeMenu} label={link.label} url={link.url} />
            ))}
          </LinkBlock>
          <Divider />
          <Heading as={'h2'} level={1}>
            Contatto e supporto
          </Heading>
          <div
            css={(theme) => ({
              marginTop: theme.spacings['l'],
            })}
          >
            <div>
              <LinkButton
                colorVariant="dark"
                css={(theme) => ({
                  '& svg': {
                    marginRight: theme.spacings.s,
                  },
                  width: 130,
                })}
                href={`mailto:${contactInfo.email}`}
                styleVariant="secondary"
              >
                <Envelope /> Scrivici
              </LinkButton>
            </div>
            <div>
              <LinkButton
                colorVariant="dark"
                css={(theme) => ({
                  '& svg': {
                    marginRight: theme.spacings.s,
                  },
                  marginTop: theme.spacings['m'],
                  width: 130,
                })}
                href={`tel:${contactInfo.phone}`}
                styleVariant="secondary"
              >
                <Phone /> Chiamaci
              </LinkButton>
            </div>
          </div>
        </MobileNavigation>
      </MenuWrapper>
    </MenuModal>
  )
}

const MenuModal = styled.div<{ paddingBottom: number }>(({ paddingBottom, theme }) => ({
  backgroundColor: theme.colors.lightBlue,
  height: '100vh',
  left: 0,
  overflowY: 'scroll',
  paddingBottom: paddingBottom,
  position: 'absolute',
  top: 0,
  width: '100vw',
  zIndex: 900,
}))

const MenuWrapper = styled.div(({ theme }) => ({
  color: theme.colors.greyscale[600],
  margin: '0 auto',
  maxWidth: 960,
}))

const MobileNavigation = styled.div(({ theme }) => ({
  marginTop: 40,
  overflowY: 'scroll',
  paddingLeft: theme.spacings['l'],
  paddingRight: theme.spacings['l'],
}))

const LinkBlock = styled.ul(({ theme }) => ({
  '& > li': {
    marginBottom: theme.spacings['xs'],
  },
  marginTop: theme.spacings['m'],
}))

const Divider = styled.hr(({ theme }) => ({
  borderColor: theme.colors.greyscale[300],
  marginBottom: theme.spacings['l'],
  marginTop: theme.spacings['l'],
}))
