import styled from '@emotion/styled'

import { mq } from '@/style-theme/media-queries'

export const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  maxWidth: 960,
  padding: `0 40px`,
  [mq.large]: {
    '& > nav': {
      flex: 1,
    },
    flexDirection: 'row',
    padding: 0,
  },
  [mq.xlarge]: {
    maxWidth: 1024,
  },
})

export const Links = styled.ul(({ theme }) => ({
  '& > li': {
    '& a:hover': {
      textDecoration: 'underline',
    },
    padding: `${theme.spacings['xs']}px 0`,
  },
  marginTop: theme.spacings['m'],
}))

export const Column = styled.nav(({ theme }) => ({
  '&:not(:first-of-type)': {
    marginTop: theme.spacings['xl'],
    [mq.large]: {
      marginTop: 0,
    },
  },
}))

export const FloatingLogo = styled.div(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.colors.greyscale[0],
  borderRadius: '50%',
  display: 'flex',
  height: 64,
  justifyContent: 'center',
  left: '50%',
  marginLeft: -32,
  position: 'absolute',
  top: -32,
  width: 64,
}))
