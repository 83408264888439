import { CSSObject, useTheme } from '@emotion/react'
import { transparentize } from 'polished'

import { Loading } from './Loading'

export type StyleVariant = 'primary' | 'secondary' | 'tertiary'
export type ShapeVariant = 'regular' | 'round'
export type ColorVariant = 'light' | 'dark'
export type SizeVariant = 'big' | 'medium' | 'small'

export interface ButtonProps {
  ariaLabel?: string
  asLink?: boolean
  className?: string
  colorVariant?: ColorVariant
  dataTestId?: string
  disabled?: boolean
  id?: string
  isActive?: boolean
  loading?: boolean
  onClick?: VoidFunction
  shapeVariant?: ShapeVariant
  sizeVariant?: SizeVariant
  styleVariant?: StyleVariant
  type?: 'submit' | 'button' | 'reset'
}

export const Button: React.FC<ButtonProps> = ({
  ariaLabel,
  asLink,
  children,
  className,
  colorVariant = 'dark',
  dataTestId,
  disabled,
  id,
  isActive,
  loading,
  onClick,
  shapeVariant = 'regular',
  sizeVariant = 'medium',
  styleVariant = 'primary',
  type,
}) => {
  const { colors, radius } = useTheme()

  const commonStyles: CSSObject = {
    '& > svg': {
      flexShrink: 0,
    },
    alignItems: 'center',
    border: '1px solid',
    borderRadius: shapeVariant === 'round' ? '50%' : radius.s,
    cursor: 'pointer',
    display: 'flex',
    fontFamily: 'Inter',
    fontSize: sizeVariant === 'big' ? 20 : 16,
    fontWeight: 600,
    justifyContent: 'center',
    padding: sizeVariant === 'small' ? '8px 16px' : '12px 16px',
    transition: 'all .3s ease',
  }

  const sizeStyles: Record<SizeVariant, CSSObject> = {
    big: {
      height: 54,
      width: shapeVariant === 'round' ? 54 : 'auto',
    },
    medium: {
      height: 48,
      width: shapeVariant === 'round' ? 48 : 'auto',
    },
    small: {
      height: 40,
      width: shapeVariant === 'round' ? 40 : 'auto',
    },
  }

  const disabledStyles: Record<StyleVariant, CSSObject> = {
    primary: {
      '&:hover': {
        backgroundColor: colors.greyscale[100],
        borderColor: colors.greyscale[100],
        color: colors.greyscale[400],
      },
      backgroundColor: colors.greyscale[100],
      borderColor: colors.greyscale[100],
      color: colors.greyscale[400],
    },
    secondary: {
      '&:hover': {
        backgroundColor: 'transparent',
        borderColor: colors.greyscale[400],
        color: colors.greyscale[400],
      },
      backgroundColor: 'transparent',
      borderColor: colors.greyscale[400],
      color: colors.greyscale[400],
    },
    tertiary: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      color: colors.greyscale[400],
    },
  }

  const activeStyles: Record<StyleVariant, Record<ColorVariant, CSSObject>> = {
    primary: {
      dark: {
        backgroundColor: colors.greyscale[500],
        color: colors.greyscale[0],
      },
      light: {
        backgroundColor: colors.greyscale[200],
        borderColor: colors.greyscale[200],
      },
    },
    secondary: {
      dark: {
        backgroundColor: transparentize(0.9, colors.greyscale[600]),
      },
      light: {
        backgroundColor: transparentize(0.9, colors.greyscale[0]!),
      },
    },
    tertiary: {
      dark: {
        backgroundColor: transparentize(0.9, colors.greyscale[600]),
      },
      light: {
        backgroundColor: transparentize(0.9, colors.greyscale[0]!),
      },
    },
  }

  const styleMap: Record<StyleVariant, Record<ColorVariant, CSSObject>> = {
    primary: {
      dark: {
        '&:active': activeStyles.primary.dark,
        '&:disabled': disabledStyles.primary,
        '&:focus-visible': {
          backgroundColor: colors.greyscale[400],
          borderColor: colors.greyscale[600], // TODO: check this with design because mockup uses a shade outside of theme colors
        },
        '&:hover': {
          backgroundColor: colors.greyscale[400],
          borderColor: colors.greyscale[400],
        },
        backgroundColor: colors.greyscale[600],
        borderColor: colors.greyscale[600],
        color: colors.greyscale[0],
      },
      light: {
        '&:active': activeStyles.primary.light,
        '&:disabled': disabledStyles.primary,
        '&:focus-visible': {
          backgroundColor: colors.greyscale[100],
          borderColor: colors.greyscale[600], // TODO: check this with design because mockup uses a shade outside of theme colors
          borderWidth: 2,
        },
        '&:hover': {
          backgroundColor: colors.greyscale[100],
          borderColor: colors.greyscale[100],
        },
        backgroundColor: colors.greyscale[0],
        borderColor: colors.greyscale[0],
        color: colors.greyscale[600],
      },
    },
    secondary: {
      dark: {
        '&:active': activeStyles.secondary.dark,
        '&:disabled': disabledStyles.secondary,
        '&:focus-visible': {
          backgroundColor: transparentize(0.95, colors.greyscale[600]),
        },
        '&:hover': {
          backgroundColor: transparentize(0.95, colors.greyscale[600]),
        },
        backgroundColor: 'transparent',
        borderColor: colors.greyscale[600],
        color: colors.greyscale[600],
      },
      light: {
        '&:active': activeStyles.secondary.light,
        '&:disabled': disabledStyles.secondary,
        '&:focus-visible': {
          backgroundColor: transparentize(0.95, colors.greyscale[0]!),
          borderWidth: 2,
        },
        '&:hover': {
          backgroundColor: transparentize(0.95, colors.greyscale[0]!),
        },
        backgroundColor: 'transparent',
        borderColor: colors.greyscale[0],
        color: colors.greyscale[0],
      },
    },
    tertiary: {
      dark: {
        '&:active': activeStyles.tertiary.dark,
        '&:disabled': disabledStyles.tertiary,
        '&:focus-visible': {
          backgroundColor: transparentize(0.95, colors.greyscale[600]),
          borderColor: colors.greyscale[600],
        },
        '&:hover': {
          backgroundColor: transparentize(0.95, colors.greyscale[600]),
        },
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: colors.greyscale[600],
      },
      light: {
        '&:active': activeStyles.tertiary.light,
        '&:disabled': disabledStyles.tertiary,
        '&:focus-visible': {
          backgroundColor: transparentize(0.95, colors.greyscale[0]!),
          borderColor: colors.greyscale[0],
        },
        '&:hover': {
          backgroundColor: transparentize(0.95, colors.greyscale[0]!),
        },
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: colors.greyscale[0],
      },
    },
  }

  const buttonStyles = styleMap[styleVariant][colorVariant]

  if (asLink) {
    return (
      <div
        aria-label={ariaLabel}
        className={isActive ? `lb-active ${className}` : className}
        css={{
          ...commonStyles,
          ...buttonStyles,
          ...sizeStyles[sizeVariant],
          ...(isActive ? activeStyles[styleVariant][colorVariant] : {}),
        }}
        data-testid={dataTestId}
        id={id}
        onClick={onClick}
        onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === ' ' && onClick !== undefined) onClick()
        }}
      >
        {children}
      </div>
    )
  }

  return (
    <button
      aria-label={ariaLabel}
      className={className}
      css={{
        ...commonStyles,
        ...buttonStyles,
        ...sizeStyles[sizeVariant],
        ...(isActive ? activeStyles[styleVariant][colorVariant] : {}),
      }}
      data-testid={dataTestId}
      disabled={disabled}
      id={id}
      type={type}
      onClick={onClick}
    >
      {loading ? <Loading colorVariant={colorVariant} styleVariant={styleVariant} /> : children}
    </button>
  )
}
