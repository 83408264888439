import React from 'react'

import { getSnippet } from './iubendaConfig'

const IubendaSnippet: React.FC = () => {
  return (
    <>
      <script dangerouslySetInnerHTML={{ __html: getSnippet() }} />
      <script async charSet="UTF-8" src="//cdn.iubenda.com/cs/iubenda_cs.js" type="text/javascript" />
    </>
  )
}

export { IubendaSnippet }
