import { IconProps } from './types'

export const Burger: React.FC<IconProps> = ({
  dataTestId,
  fillColor,
  height = '16',
  viewBox = '0 0 17 16',
  width = '17',
}) => {
  return (
    <svg
      data-testid={dataTestId}
      fill="none"
      height={height}
      viewBox={viewBox}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.5 3.33337H14.5V5.33337H2.5V3.33337Z" fill={fillColor ? fillColor : 'currentColor'} />
      <path d="M2.5 7.00004H14.5V9.00004H2.5V7.00004Z" fill={fillColor ? fillColor : 'currentColor'} />
      <path d="M14.5 10.6667H2.5V12.6667H14.5V10.6667Z" fill={fillColor ? fillColor : 'currentColor'} />
    </svg>
  )
}
