/* eslint-disable react/display-name */
import Link, { LinkProps } from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

import { Button, ColorVariant, SizeVariant, StyleVariant } from '@/components/baseUI/Button'

type LinkButtonProps = React.HTMLProps<HTMLAnchorElement> &
  LinkProps & {
    ariaLabel?: string
    className?: string
    colorVariant?: ColorVariant
    dataTestId?: string
    onClick?: VoidFunction
    sizeVariant?: SizeVariant
    styleVariant?: StyleVariant
  }

export const LinkButton: React.FC<LinkButtonProps> = ({
  ariaLabel,
  children,
  className,
  colorVariant = 'dark',
  href,
  onClick,
  rel,
  sizeVariant = 'medium',
  styleVariant = 'primary',
  target,
}) => {
  const router = useRouter()

  return (
    <Link passHref href={href}>
      <a aria-label={ariaLabel} className={className} css={{ display: 'inline-block' }} rel={rel} target={target}>
        <Button
          asLink
          colorVariant={colorVariant}
          isActive={router.pathname === href}
          sizeVariant={sizeVariant}
          styleVariant={styleVariant}
          onClick={onClick}
        >
          {children}
        </Button>
      </a>
    </Link>
  )
}
