import styled from '@emotion/styled'

import { mq } from '@/style-theme/media-queries'

/** This is the global wrapper to box the content in a centered grid */
export const Grid = styled.div(({ theme }) => ({
  margin: '0 auto',
  paddingLeft: theme.spacings['l'],
  paddingRight: theme.spacings['l'],
  width: '100%',
  [mq.xsmall]: {
    width: 360,
  },
  [mq.medium]: {
    width: 720,
  },
  [mq.large]: {
    paddingLeft: theme.spacings['m'],
    paddingRight: theme.spacings['m'],
    width: 960,
  },
  [mq.xlarge]: { width: 1024 },
  [mq.xxlarge]: {
    width: 1154,
  },
}))
