import Link from 'next/link'
import { useRouter } from 'next/router'

import { useDevice } from '@/context/device-context'
import { NavigationLink } from '@/types/global'

const removeHashesFromPath = (path: string) => path.split('#')[0]

export const HeaderLink: React.FC<NavigationLink & { closeMenu: VoidFunction }> = ({
  closeMenu,
  external,
  label,
  url,
}) => {
  const device = useDevice()

  const router = useRouter()
  const isCurrent = removeHashesFromPath(router.asPath) === url
  const shouldCloseMenu = device === 'mobile' && isCurrent

  const active = isCurrent

  return (
    <li>
      {external ? (
        <a
          css={(theme) => ({
            '&:hover': { textShadow: `0.5px 0 0 ${theme.colors.greyscale[600]}` },
            borderBottom: active ? `1px solid ${theme.colors.greyscale[600]}` : 'none',
            textShadow: active ? `0.5px 0 0 ${theme.colors.greyscale[600]}` : 'none',
          })}
          href={url}
          rel="noopener noreferrer"
          target="_blank"
        >
          {label}
        </a>
      ) : shouldCloseMenu ? (
        <a onClick={closeMenu}>{label}</a>
      ) : (
        <Link passHref href={url}>
          <a
            css={(theme) => ({
              '&:hover': { textShadow: `0.5px 0 0 ${theme.colors.greyscale[600]}` },
              borderBottom: active ? `1px solid ${theme.colors.greyscale[600]}` : 'none',
              textShadow: active ? `0.5px 0 0 ${theme.colors.greyscale[600]}` : 'none',
            })}
          >
            {label}
          </a>
        </Link>
      )}
    </li>
  )
}
