import styled from '@emotion/styled'

export const Navigation = styled.ul(({ theme }) => ({
  '& > li': {
    ':last-child': {
      paddingRight: 0,
    },
    padding: '0 40px',
  },
  '& a': {
    '&:hover': {
      color: theme.colors.greyscale[600],
    },
    color: theme.colors.greyscale[600],
    fontSize: 16,
  },
  display: 'flex',
}))
