import Image from 'next/image'
import Link from 'next/link'
import React, { useState } from 'react'

import { mainMenuLinks } from '@/constants/menus'
import { useDevice } from '@/context/device-context'

import { HeaderLink } from './HeaderLink'
import { MobileMenu } from './MobileMenu'
import { MobileMenuButton } from './MobileMenuButton'
import { Navigation } from './styles'

export const HeaderContent: React.FC = () => {
  const device = useDevice()

  const [menuOpen, setMenuOpen] = useState<boolean>(false)

  const openMenu = () => setMenuOpen(true)
  const closeMenu = () => setMenuOpen(false)

  const logoSrc = device === 'desktop' ? '/logo-desktop.svg' : '/logo-mobile.svg'
  return (
    <div
      css={(theme) => ({
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        margin: '0 auto',
        paddingLeft: theme.spacings['m'],
        paddingRight: theme.spacings['m'],
      })}
    >
      <Link passHref href={'/'}>
        <a aria-label="Homepage">
          <Image alt="" height={device === 'mobile' ? 46 : 63} src={logoSrc} width={device === 'mobile' ? 110 : 156} />
        </a>
      </Link>
      <nav aria-label="menu principale" css={{ display: 'flex', flex: 1, flexDirection: 'row-reverse' }}>
        {device === 'mobile' ? (
          <React.Fragment>
            <MobileMenuButton handleClick={openMenu} type="open" />
            {menuOpen && <MobileMenu closeMenu={closeMenu} />}
          </React.Fragment>
        ) : (
          <Navigation data-testid="header-navigation">
            {mainMenuLinks.map((link) => (
              <HeaderLink
                key={link.url}
                closeMenu={closeMenu}
                external={link.external}
                label={link.label}
                url={link.url}
              />
            ))}
          </Navigation>
        )}
      </nav>
    </div>
  )
}
