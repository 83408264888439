type IubendaButton = 'acceptButton' | 'customizeButton' | 'rejectButton'
type IubendaButtonColorProps = {
  backgroundColor: string
  textColor: string
}

export const getSnippet = () => {
  const cookiePolicyId = 24801522
  const siteId = 2558340

  const widgetBackgroundColor = '#1D1D1B'
  const widgetTextColor = '#FFFFFF'

  const colors: Record<IubendaButton, IubendaButtonColorProps> = {
    acceptButton: {
      backgroundColor: '#FFFFFF',
      textColor: '#1D1D1B',
    },
    customizeButton: {
      backgroundColor: 'transparent',
      textColor: '#FFFFFF',
    },
    rejectButton: {
      backgroundColor: '#1D1D1B',
      textColor: '#FFFFFF',
    },
  }

  /*
    GTM Events:
    - User expresses "Interazioni e funzionalità semplici" consent => iubenda_interaction_consent_expressed
    - User expresses "Miglioramento dell’esperienza" consent => iubenda_experience_consent_expressed
    - User expresses "Misurazione" consent => iubenda_measurement_consent_expressed
    - User expresses "Targeting e Pubblicità" consent => iubenda_targeting_consent_expressed
   */
  const customCallbacks = `
    "callback": {
      "onPreferenceExpressed": function(preferences) {
        var grantedInteraction = preferences.purposes[2];
        var grantedExperience = preferences.purposes[3];
        var grantedMeasurement = preferences.purposes[4];
        var grantedTargeting = preferences.purposes[5];
        if (typeof window.dataLayer !== 'undefined') {
            window.dataLayer.push({
              'casavo-mutui.cookie-preference.interaction': grantedInteraction,
              'event': 'iubenda_interaction_consent_expressed',
              'page': document.location.pathname + document.location.search,
              'location': document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search
            });
            window.dataLayer.push({
              'casavo-mutui.cookie-preference.experience': grantedExperience,
              'event': 'iubenda_experience_consent_expressed',
              'page': document.location.pathname + document.location.search,
              'location': document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search
            });
            window.dataLayer.push({
              'casavo-mutui.cookie-preference.measurement': grantedMeasurement,
              'event': 'iubenda_measurement_consent_expressed',
              'page': document.location.pathname + document.location.search,
              'location': document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search
            });
            window.dataLayer.push({
              'casavo-mutui.cookie-preference.targeting': grantedTargeting,
              'event': 'iubenda_targeting_consent_expressed',
              'page': document.location.pathname + document.location.search,
              'location': document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search
            });
        }
      }
    },`

  return `
  var _iub = _iub || [];
  _iub.csConfiguration = {
    "consentOnContinuedBrowsing":false,
    "countryDetection":true,
    "gdprAppliesGlobally":false,
    "invalidateConsentWithoutLog":true,
    "perPurposeConsent":true,
    "siteId":${siteId},
    "whitelabel":false,
    "cookiePolicyId":${cookiePolicyId},
    "lang":"it",
    ${customCallbacks}
    "banner":{
      "acceptButtonCaptionColor":"${colors.acceptButton.textColor}",
      "acceptButtonColor":"${colors.acceptButton.backgroundColor}",
      "acceptButtonDisplay":true,
      "backgroundColor":"${widgetBackgroundColor}",
      "closeButtonRejects":true,
      "customizeButtonCaptionColor":"${colors.customizeButton.textColor}",
      "customizeButtonColor":"${colors.customizeButton.backgroundColor}",
      "customizeButtonDisplay":true,
      "explicitWithdrawal":true,
      "listPurposes":true,
      "position":"float-bottom-center",
      "rejectButtonCaptionColor":"${colors.rejectButton.textColor}",
      "rejectButtonColor":"${colors.rejectButton.backgroundColor}",
      "rejectButtonDisplay":true,
      "textColor":"${widgetTextColor}",
      "content": "<h2>Permettici di conoscerti meglio</h2> Usiamo i cookies per rendere la navigazione sul nostro sito più piacevole possibile. Possiamo anche presentarti le pubblicità più rilevanti e contenuti personalizzati. Se invece preferisci dedicare un po’ del tuo tempo a impostare i cookie che possiamo utilizzare, fai clic su “Personalizza”. Potrai comunque cambiare le tue preferenze in seguito."
    }
  };
  `
}
