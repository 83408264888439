import { IconProps } from './types'

export const Envelope: React.FC<IconProps> = ({
  dataTestId,
  fillColor,
  height = '12',
  viewBox = '0 0 15 12',
  width = '15',
}) => {
  return (
    <svg
      data-testid={dataTestId}
      fill="none"
      height={height}
      viewBox={viewBox}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M12.6428 0C13.5896 0 14.3571 0.767512 14.3571 1.71429V10.2857C14.3571 11.2325 13.5896 12 12.6428 12H2.35711C1.41033 12 0.642822 11.2325 0.642822 10.2857V1.71429C0.642822 0.767512 1.41033 0 2.35711 0H12.6428ZM12.6428 4.92857L7.49692 6.85714L2.35711 4.92857V10.2857H12.6428V4.92857ZM12.6428 1.71429H2.35711V3.21257L7.5149 5.14286L12.6428 3.216V1.71429Z"
        fill={fillColor ? fillColor : 'currentColor'}
        fillRule="evenodd"
      />
    </svg>
  )
}
