import Head from 'next/head'
import React from 'react'

import { ConsentSnippet } from '@/components/ConsentSnippet'
import { IubendaSnippet } from '@/components/IubendaSnippet'

import { useTagManager } from '../../hooks/useTagManager'
import { Footer } from '../Footer'
import { Header } from '../Header'

import { Main } from './styles'
type Props = {
  backgroundColor?: 'white' | 'lightgray'
  metaDescription: string
  metaTitle: string
  transparentHeader?: boolean
}
export const Page: React.FC<Props> = ({ backgroundColor = 'lightgray', children, metaDescription, metaTitle }) => {
  /** GTM pageview event */
  useTagManager()

  return (
    <React.Fragment>
      <Head>
        <ConsentSnippet />
        <IubendaSnippet />
        <meta content={metaDescription} name="description" />
        <title>{metaTitle} | Casavo Mutui</title>
      </Head>
      <div
        css={(theme) => ({
          backgroundColor: backgroundColor === 'white' ? theme.colors.greyscale[0] : theme.colors.offWhite,
          minHeight: '100vh',
        })}
        data-testid="page-wrapper"
      >
        <Header />
        <Main css={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 245px)' }} id="main-content">
          {children}
        </Main>
        <Footer />
      </div>
    </React.Fragment>
  )
}
